<template>
	<div class="analyse-home" v-if="page">
		<h2 class="text-secondary">{{ page.title }}</h2>

		<div class="row mb-5">
			<div class="col-12 col-lg-8 order-1 order-lg-0">
				<div v-html="page.content"/>
			</div>
			<div class="col-12 col-lg-5 order-0 order-lg-1">
				<MediaEmbed :page="page" />
			</div>
		</div>

		<Themes @click-theme="chooseTheme" slug="analyse"/>
	</div>
</template>

<script>
// @ is an alias to /src
import api from '@/data/api'
import Themes from '@/components/Themes.vue';
import {themeMixin} from "@/mixins/chooseThemeMixin";
import MediaEmbed from "@/components/MediaEmbed";

export default {
	name: 'Home',
	components: {MediaEmbed, Themes},
    mixins: [themeMixin],
	data() {
		return {
			pageTitle: 'Ontdek per dimensie waar je staat',
			page: null
		}
	},
	mounted() {
		api.fetchPage(5526).then(response => {
			this.page = response;
		});

		const breadcrumbs = [
			{
				'label': 'Home',
				'path': '/'
			},
			{
				'label': 'Analyse',
			},
		];
		this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
	}
}
</script>
